// extracted by mini-css-extract-plugin
export var header = "navigation-module--header--os5PY";
export var sanity = "navigation-module--sanity--LDChG";
export var mobileHeader = "navigation-module--mobileHeader--bqtlo";
export var wrapper = "navigation-module--wrapper--CL+iS";
export var logoDesktop = "navigation-module--logoDesktop--Coeek";
export var logoMobile = "navigation-module--logoMobile--cDJnw";
export var logo = "navigation-module--logo--wtPTJ";
export var ehcn = "navigation-module--ehcn--EABHa";
export var coling = "navigation-module--coling--eEKBX";
export var rv = "navigation-module--rv--HwkaD";
export var searchWrapper = "navigation-module--searchWrapper--84SRm";
export var langWrapper = "navigation-module--langWrapper--Mo7iC";
export var globe = "navigation-module--globe--55ID7";
export var lang = "navigation-module--lang--AVzx2";
export var menu = "navigation-module--menu--9qD7o";
export var multinav = "navigation-module--multinav--EAns8";
export var on = "navigation-module--on--dX8GC";
export var close = "navigation-module--close---hjna";
export var email = "navigation-module--email--SlqUk";